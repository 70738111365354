import {configureStore} from '@reduxjs/toolkit';
import  LogginReduces from './login';
import snackBarSlice  from './snackBar';
import customizationReducer from './customizationReducer'
import menuReducer from './menu'
import orderReducer from './order'
import dashboaedSlice from "./dashboard"
import tabReducer from "./tabaction"

export default configureStore({
    reducer : {
        logged:LogginReduces,
        snackBar:snackBarSlice,
        customization: customizationReducer,
        menu:menuReducer,
        order:orderReducer,
        dashboard :dashboaedSlice,
        tabaction : tabReducer

    }
})