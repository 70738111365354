import { lazy } from "react";
import Loadable from "../ui-component/Loadable";
const AuthGuard = Loadable(
  lazy(() => import("../views/pages/authentication/AuthGuard"))
);
const MainLayout = Loadable(lazy(() => import("../layout/MainLayout")));
const DashboardDefault = Loadable(
  lazy(() => import("../views/dashboard/Default"))
);
const MainOrders = Loadable(lazy(() => import("../views/order/MainOrders")));
const OrderDetails = Loadable(
  lazy(() => import("../views/order/OrderDetails"))
);

//store
const AddStore = Loadable(lazy(() => import("../views/store/AddStore")));
const ListStore = Loadable(lazy(() => import("../views/store/ListStore")));
const ChangePassword = Loadable(lazy(() => import("../views/pages/authentication/ChangePassword")));
const StoreSettings = Loadable(
  lazy(() => import("../views/store/StoreSettings"))
);
const StoreProfile = Loadable(
  lazy(() => import("../views/store/storeHome"))
);
const Addon = Loadable(
  lazy(() => import("../views/store/addons/Addon"))
);
const MenuOptions = Loadable(
  lazy(() => import("../views/store/options/MenuOptions"))
);
const Category = Loadable(
  lazy(() => import("../views/store/category/Category"))
);
const Menu = Loadable(
  lazy(() => import("../views/store/menu/Menu"))
);
const MenuUpload = Loadable(
  lazy(() => import("../views/store/adminAction/MenuUpload"))
);

//order
const ReviewOrder = Loadable(
  lazy(() => import("../views/order/review/ReviewOrder"))
);
const DeliveryBoyAdd = Loadable(
  lazy(() => import("../views/deliveryBoy/DeliveryBoyAdd"))
);
const CustomerService = Loadable(
  lazy(() => import("../views/customerService/CustomerService"))
);


//performance Dialy
const DialyPerformance = Loadable(
  lazy(() => import("../views/report/performance/DialyPerformance"))
);
const WeeklyPerformance = Loadable(
  lazy(() => import("../views/report/performance/WeeklyPerformance"))
);
const MonthlyPerformance = Loadable(
  lazy(() => import("../views/report/performance/MonthlyPerformance"))
);

//Summary
const DialySummary = Loadable(
  lazy(() => import("../views/report/summary/DialySummary"))
);
const WeeklySummary = Loadable(
  lazy(() => import("../views/report/summary/WeeklySummary"))
);
const MonthlySummary = Loadable(
  lazy(() => import("../views/report/summary/MonthlySummary"))
);
//invoice
const MonthlyInvoice = Loadable(
  lazy(() => import("../views/report/MonthlyInvoice"))
);


//complaits
const Complaints = Loadable(
  lazy(() => import("../views/feedback/Complaints"))
);



const ViewMenu = Loadable(
  lazy(() => import("../views/store/menu/ViewMenu"))
);

//audit trailas
const AuditTrails = Loadable(
  lazy(() => import("../views/audit/AuditTrails"))
);

//acount settings
const BranchPartner = Loadable(
  lazy(() => import("../views/accounts/branchPartner/BranchPartner"))
);

const CustomerCareAdmin = Loadable(
  lazy(() => import("../views/accounts/customercare/CustomerCareAdmin"))
);
const CustomerCareExecutive = Loadable(
  lazy(() => import("../views/accounts/customercare/CustomerCareExecutive"))
);

const AccountantAdmin = Loadable(
  lazy(() => import("../views/accounts/accountants/AccountantAdmin"))
);
const AccountsManager = Loadable(
  lazy(() => import("../views/accounts/accountants/AccountsManager"))
);
const AccountsExecutive = Loadable(
  lazy(() => import("../views/accounts/accountants/AccountsExecutive"))
);
const MarketingAdmin = Loadable(
  lazy(() => import("../views/accounts/marketing/MarketingAdmin"))
);
const MarketingExecutive = Loadable(
  lazy(() => import("../views/accounts/marketing/MarketingExecutive"))
);
const OfficeStaff = Loadable(
  lazy(() => import("../views/accounts/officeStaff/OfficeStaff"))
);



const MachineStatus = Loadable(
  lazy(() => import("../views/settings/machine/machineStatus"))
);

const Machine = Loadable(
  lazy(() => import("../views/settings/machine/machines"))
);
const UserUpdate = Loadable(
  lazy(() => import("../views/pages/authentication/updateuser"))
);

const DeliveryBoyList = Loadable(
  lazy(() => import("../views/deliveryBoy/DeliveryBoyList"))
);
const UserNotification = Loadable(
  lazy(() => import("../views/userNotification/userNotification"))
);
const ViewTaxInvoice =  Loadable(
  lazy(() => import("../views/taxInvoice/view"))
);
const StoreOrder =  Loadable(
  lazy(() => import("../views/store/storeOrder"))
);
const MainOrdersGrocery = Loadable(lazy(() => import("../views/order/groceryOrder/MainOrders")));
const OrderDetailsGrocery = Loadable(
  lazy(() => import("../views/order/groceryOrder/OrderDetails"))
);
const RejectedOrder =  Loadable(lazy(() => import("../views/order/rejectedList")));
const RejectedOrderGrocery =  Loadable(lazy(() => import("../views/order/groceryOrder/rejectedList")));
const OnlineOrder = Loadable(
  lazy(() => import("../views/report/onlineOrder/OnlineOrder"))
);
const MainRoutes = {
  path: "/",
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: "/dashboard",
      element: <DashboardDefault />,
    },
    {
      path: "/store",
      children: [
        {
          path: "add",
          element: <AddStore />,
        },
        {
          path: "list",
          element: <ListStore />,
        },
        {
          path: "settings",
          element: <StoreSettings />,
        },
        {
          path: "storeProfile/:id",
          element: <StoreProfile />,
        },
        {
          path: "storeProfile/addons/:id",
          element: <Addon />,
        },
        {
          path: "storeProfile/options/:id",
          element: <MenuOptions />,
        },
        {
          path: "storeProfile/category/:id",
          element: <Category />,
        },
        {
          path: "storeProfile/menu/:id",
          element: <Menu />,
        },
        {
          path: "storeProfile/viewMenu/:storeId/:catID",
          element: <ViewMenu />,
        },
        {
          path: "menuUpload",
          element: <MenuUpload />,
        },
        {
          path: "storeorder/:id",
          element: <StoreOrder />,
        },
      ],
    },
    {
      path: "/deliveryBoy",
      children: [
        {
          path: "add",
          element: <DeliveryBoyAdd />,
        },
        {
          path: "list",
          element: <DeliveryBoyList />,
        },
      ],
    },
    {
      path: "/customerService",
      element: <CustomerService />,
    },
    {
      path: "/branch",
      children: [
        {
          path: "order",
          element: <MainOrders />,
        },
      ],
    },
    {
      path: "/order",
      children: [
        {
          path: "view",
          element: <MainOrders />,
        },
        {
          path: "review",
          element: <ReviewOrder />,
        },
        {
          path: "orderDetails/:id",
          element: <OrderDetails />,
        },
        {
          path: "grocery/view",
          element: <MainOrdersGrocery />,
        },
        {
          path: "grocery/orderDetails/:id",
          element: <OrderDetailsGrocery />,
        },
        {
          path: "rejected",
          element: <RejectedOrder />,
        },
        {
          path: "rejected/grocery",
          element: <RejectedOrderGrocery />,
        },
      ],
    },
   
    {
      path: "/report",
      children: [
        {
          path: "performance_report",
          children: [
            {
              path: "dialy",
              element: <DialyPerformance />,
            },
            {
              path: "weekly",
              element: <WeeklyPerformance />,
            },
            {
              path: "monthly",
              element: <MonthlyPerformance />,
            },
          ]
        },
        {
          path: "summary_report",
          children: [
            {
              path: "dialy",
              element: <DialySummary />,
            },
            {
              path: "weekly",
              element: <WeeklySummary />,
            },
            {
              path: "monthly",
              element: <MonthlySummary />,
            },
          ]
        },
       
        {
          path: "onlineorder",
          element: <OnlineOrder />,
        },
        {
          path: "invoice",
          element: <MonthlyInvoice />,
        },
      ],
    },
 
    {
      path: "/settings",
      // element: <Notification />,
      children: [
        {
          path: "status",
          element: <MachineStatus/>,
        },
        {
          path: "machine",
          element: <Machine />,
        },
      ]
    },
    {
      path: "/feedback",
      element: <Complaints />,
    },
    {
      path: "/profile",
      element: <ChangePassword />,
    },
    {
      path: "/audit-tails",
      element: <AuditTrails />,
    },
    {
      path: "/accounts",
      children: [
        {
          path: "branch-partner",
          element: <BranchPartner />,
        },
        {
          path: "custumercare-admin",
          element: <CustomerCareAdmin />,
        },
        {
          path: "custumercare-executive",
          element: <CustomerCareExecutive />,
        },
        {
          path: "accountant-admin",
          element: <AccountantAdmin />,
        },
        {
          path: "accounts-manager",
          element: <AccountsManager />,
        },
        {
          path: "accounts-executive",
          element: <AccountsExecutive />,
        },
        {
          path: "marketing-admin",
          element: <MarketingAdmin />,
        },
        {
          path: "marketing-executive",
          element: <MarketingExecutive />,
        },
        {
          path: "office-staff",
          element: <OfficeStaff />,
        }
      ],
    },
    {
      path: "/userUpdate",
      element: <UserUpdate />,
    },
    {
      path: "/profile",
      element: <ChangePassword />,
    },
    {
      path: "/notification/view",
      element: <UserNotification />,
    },
    {
      path: "/taxinvoice",
      children: [
        {
          path: "view",
          element: <ViewTaxInvoice />,
        }
      ],
    },
  ],
};

export default MainRoutes;
